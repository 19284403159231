<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption" @change="monitorPointChange">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="任务名称" prop="task_id">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.task_id" @change="getMaintenanceUnitTaskInfoList(formData.task_id)" show-search :filter-option="filterOption">
            <a-select-option v-for="(item,index) in workUnitsTaskList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业类型" prop="work_type">
          <a-select :disabled="showType=='detail'" v-model="formData.work_type" @change="getWorkUnitsTask(formData.work_type)">
            <a-select-option value="清洁">清洁</a-select-option>
            <a-select-option value="绿化">绿化</a-select-option>
            <a-select-option value="消杀">消杀</a-select-option>
            <a-select-option value="白蚁防治">白蚁防治</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业子类型" prop="work_child_type">
          <a-select :disabled="showType=='detail'" v-model="formData.work_child_type">
            <a-select-option v-if="formData.work_type==='清洁'" value="保洁">保洁</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="清扫">清扫</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="大做">大做</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="高空">高空</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="专项清洁">专项清洁</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="垃圾处理">垃圾处理</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="养护">养护</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="病虫害防治">病虫害防治</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="补种">补种</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="加固">加固</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭鼠">灭鼠</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭蚊蝇">灭蚊蝇</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭蟑">灭蟑</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="消毒">消毒</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业点名称" prop="location">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.location" show-search :filter-option="filterOption" @change="getMonitorNum">
            <a-select-option v-for="(item,index) in workPointList" :key="index" :value="item.point_qrcode">{{item.point_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业单位" prop="unit_name">
          <a-input :disabled="true" v-model.trim="formData.unit_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="区域" prop="region">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.region"></a-input>
        </a-form-model-item>
        <a-form-model-item label="楼层" prop="floor">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.floor"></a-input>
        </a-form-model-item>
<!--        <a-form-model-item v-if="showType=='detail'" label="打点位置" prop="location_name">-->
<!--          <a-input :disabled="showType=='detail'" v-model.trim="formData.location_name"/>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="摄像机编号" prop="monitor_num">
          <a-input :disabled="true" v-model.trim="formData.monitor_num"></a-input>
        </a-form-model-item>
        <a-form-model-item label="频次" prop="frequency">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.frequency"></a-input>
        </a-form-model-item>
        <a-form-model-item label="岗位名称" prop="post_name">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.post_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="作业内容" prop="content">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.content" style="width: 385px"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 385px"/>
        </a-form-model-item>
        <a-form-model-item label="计划状态" prop="plan_status">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.plan_status">
            <a-select-option :value="1">启动</a-select-option>
            <a-select-option :value="0">停止</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="初次启动时间" prop="first_date">
          <a-date-picker :disabled="showType=='detail'" v-model="formData.first_date" style="width: 390px;"></a-date-picker>
        </a-form-model-item>
        <a-divider></a-divider>
        <div style="color: red;margin-left: 70px;margin-top: -10px">作业计划填写注意事项：</div>
        <div style="color: red;margin-left: 90px;">1.如果当月每天都有作业任务，则只需要写F即可，写F后，当月计划不能再有其它内容</div>
        <div style="color: red;margin-left: 90px">2.如果当月某天有作业任务，则填写作业日期，例如7，表示该月的7号有作业任务；如果一个月有多个任务，用英文“,”分开，例如7,15</div>
        <div style="color: red;margin-left: 90px">3.如果当月没有任何作业任务，请填写0</div>
        <a-form-model-item label="计划年份" prop="year">
          <a-select :disabled="showType=='detail'" v-model="formData.year">
            <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="" prop=""></a-form-model-item>
        <a-form-model-item label="一月" prop="month_one">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_one" @blur="formData.month_one=formData.month_one.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="二月" prop="month_two">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_two" @blur="formData.month_two=formData.month_two.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="三月" prop="month_three">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_three" @blur="formData.month_three=formData.month_three.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="四月" prop="month_four">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_four" @blur="formData.month_four=formData.month_four.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="五月" prop="month_five">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_five" @blur="formData.month_five=formData.month_five.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="六月" prop="month_six">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_six" @blur="formData.month_six=formData.month_six.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="七月" prop="month_seven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_seven" @blur="formData.month_seven=formData.month_seven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="八月" prop="month_eight">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eight" @blur="formData.month_eight=formData.month_eight.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="九月" prop="month_nine">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_nine" @blur="formData.month_nine=formData.month_nine.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十月" prop="month_ten">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_ten" @blur="formData.month_ten=formData.month_ten.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十一月" prop="month_eleven">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_eleven" @blur="formData.month_eleven=formData.month_eleven.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
        <a-form-model-item label="十二月" prop="month_twelve">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.month_twelve" @blur="formData.month_twelve=formData.month_twelve.replace(/[^\w\,]/g,'').toUpperCase()"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addWorkPlan,
  getWorkPlanListByCondition, getWorkPointListByCondition, getWorkUnitsList, getWorkUnitsListByCondition,
  modifyWorkPlan
} from "A/businessmanagement";
import {getCache} from "U/index";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '750px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum:'',
        work_type:'',
        work_child_type:'',
        floor:'',
        region:'',
        garbage_type:'',
        clear_type:'',
        unit:'',
        num:'',
        monitor_num:'',
        content:'',
        location:'',
        location_name:'',
        frequency:'',
        post_name:'',
        plan_status:'',
        task_id:'',
        month_one:'',
        month_two:'',
        month_three:'',
        month_four:'',
        month_five:'',
        month_six:'',
        month_seven:'',
        month_eight:'',
        month_nine:'',
        month_ten:'',
        month_eleven:'',
        month_twelve:'',
        year:'',
        first_date:null,
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        task_id: [{required: true, message: '请选择任务名称'}],
        work_type: [{required: true, message: '请选择作业类型'}],
        work_child_type: [{required: true, message: '请选择作业子类型'}],
        region: [{required: true, message: '请输入区域'}],
        // floor: [{required: true, message: '请输入楼层'}],
        content: [{required: true, message: '请输入作业内容'}],
        location: [{required: true, message: '请选择作业点名称'}],
        frequency: [{required: true, message: '请选择频次'}],
        post_name: [{required: true, message: '请输入岗位名称'}],
        plan_status: [{required: true, message: '请选择计划状态'}],
        first_date: [{required: true, message: '请选择首次保养时间'}],
        year: [{required: true, message: '请选择计划年份'}],
        month_one: [
          {required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/^[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_two: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_three: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('请安装上述提示规则填写')
              }
            }, trigger: 'blur'
          }
        ],
        month_four: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_five: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_six: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_seven: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_eight: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_nine: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_ten: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_eleven: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
        month_twelve: [{required: true, message: '计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！'},
          {
            validator: (rule, value, callback) => {
              if(/[F]|[0-9]|,[12][0-9]|,3[01]$/.test(value)) {
                callback();
              }else {
                callback('计划内容填写出错，请仔细阅读‘作业计划填写注意事项’提示，谢谢！')
              }
            }, trigger: 'blur'
          }
        ],
      },
      monitorpointList:[],
      workUnitsTaskList:[],
      workPointList:[],
      yearOptions: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getYearOptions();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    this.getWorkUnitsTask("");
    this.getQueryCondition();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getQueryCondition(){
      getWorkPointListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res && res.returncode == '0') {
          this.workPointList=res.item
        }
      })
    },
    getMonitorNum(){
      getWorkPointListByCondition({userdepsid:this.userInfo.userdepid,monitorpointnum:this.formData.monitorpointnum,point_qrcode:this.formData.location}).then(res=>{
        if(res && res.returncode == '0') {
          this.formData.monitor_num=res.item[0].monitor_num
          this.formData.region=res.item[0].region
          this.formData.floor=res.item[0].floor
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.formData.contract_num=""
    },
    initDetail() {
      if (this.detailData && this.detailData.plan_id) {
        if(this.showType == 'edit' || this.showType == 'detail'||this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              plan_id: this.detailData.plan_id
            }
            getWorkPlanListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if(this.showType === 'paste'){
                  this.formData.plan_status=this.detailData.plan_status
                  this.formData.month_one=this.detailData.month_one
                  this.formData.month_two=this.detailData.month_two
                  this.formData.month_three=this.detailData.month_three
                  this.formData.month_four=this.detailData.month_four
                  this.formData.month_five=this.detailData.month_five
                  this.formData.month_six=this.detailData.month_six
                  this.formData.month_seven=this.detailData.month_seven
                  this.formData.month_eight=this.detailData.month_eight
                  this.formData.month_nine=this.detailData.month_nine
                  this.formData.month_ten=this.detailData.month_ten
                  this.formData.month_eleven=this.detailData.month_eleven
                  this.formData.month_twelve=this.detailData.month_twelve
                }
                getWorkUnitsList({work_type:this.formData.work_type,monitorpointnum:this.formData.monitorpointnum}).then(res=>{
                  if(res&&res.returncode=='0'){
                    this.workUnitsTaskList=res.item
                  }
                })
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.first_date=moment(this.formData.first_date).format("YYYYMMDD")
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addWorkPlan(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyWorkPlan(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    monitorPointChange(){
      this.getWorkUnitsTask(this.formData.work_type)
      getWorkPointListByCondition({monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res && res.returncode == '0') {
          this.workPointList=res.item
        }
      })
    },
    getWorkUnitsTask(work_type){
      getWorkUnitsList({work_type:work_type,monitorpointnum:this.formData.monitorpointnum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.workUnitsTaskList=res.item
        }
      })
    },
    getMaintenanceUnitTaskInfoList(task_id){
      getWorkUnitsListByCondition({task_id:task_id}).then(res=>{
        if(res&&res.returncode=='0'){
          this.formData.unit_id=res.item[0].unit_id
          this.formData.unit_name=res.item[0].unit_name
          this.formData.contract_num=res.item[0].contract_num
        }
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=3; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>