<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="作业类型" prop="work_type" style="width: 25%">
              <a-select v-model="queryParams.work_type">
                <a-select-option value="清洁">清洁</a-select-option>
                <a-select-option value="绿化">绿化</a-select-option>
                <a-select-option value="消杀">消杀</a-select-option>
                <a-select-option value="白蚁防治">白蚁防治</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="作业人员" prop="work_person" style="width: 25%">
              <a-input v-model="queryParams.work_person"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="没扫码天数" prop="no_scancode" style="width: 25%">
                <a-select v-model="queryParams.no_scancode">
                  <a-select-option value="0">小于等于10天</a-select-option>
                  <a-select-option value="1">大于10天</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="每天扫码点数" prop="day_scancode" style="width: 25%">
                <a-select v-model="queryParams.day_scancode">
                  <a-select-option value="0">小于三个点</a-select-option>
                  <a-select-option value="1">大于等于三个点</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="月份" prop="month" style="width: 25%">
                <a-month-picker v-model="queryInstallDate" @change="onChange" />
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
<!--            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="order_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="work_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="plan_status" slot-scope="value">
            <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
          </span>
          <span slot="no_scancode" slot-scope="value">
            <span :style="value?value>10?{'color':'red'}:{'color':''}:{'color':''}" :title="value">{{value}}</span>
          </span>
          <span slot="day_scancode" slot-scope="value">
            <span :style="value?value<3?{'color':'red'}:{'color':''}:{'color':''}" :title="value">{{value}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
<!--                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.order_id">修改</a-menu-item>-->
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.order_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/environment/workPlan/addOrEditModal";
import {
  getEnvironmentAnalysisList,
} from "A/businessmanagement";
export default {
  name: "workOrder",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        work_type:'',
        work_person:'',
        work_address:'',
        work_status:'',
        monitor_num:'',
        no_scancode:'',
        day_scancode:'',
        month:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '作业类型',
          dataIndex: 'work_type',
          key: 'work_type',
          ellipsis: true,
        },
        {
          title: '作业子类型',
          dataIndex: 'work_child_type',
          key: 'work_child_type',
          ellipsis: true,
        },
        {
          title: '作业人员',
          dataIndex: 'work_person',
          key: 'work_person',
          ellipsis: true,
        },
        {
          title: '作业人员账号',
          dataIndex: 'work_person_account',
          key: 'work_person_account',
          ellipsis: true,
        },
        {
          title: '作业单位',
          dataIndex: 'unit_name',
          key: 'unit_name',
          ellipsis: true,
        },
        {
          title: '没扫码天数',
          dataIndex: 'no_scancode',
          key: 'no_scancode',
          ellipsis: true,
          scopedSlots: { customRender: 'no_scancode' },
        },
        {
          title: '每天扫码点数',
          dataIndex: 'day_scancode',
          key: 'day_scancode',
          ellipsis: true,
          scopedSlots: { customRender: 'day_scancode' },
        },
        {
          title: '月份',
          dataIndex: 'month',
          key: 'month',
          ellipsis: true,
          scopedSlots: { customRender: 'month' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "环境管理作业分析报表"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate() {
      this.queryParams.month=moment(this.queryInstallDate).format("YYYY-MM")
    },
  },
  created() {
    this.getCurrentDate()
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      if(month<10){
        month="0"+month
      }
      this.queryInstallDate = year + "-" + month;
    },
    onChange(date, dateString) {
      this.queryParams.month=dateString
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEnvironmentAnalysisList(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getCurrentDate()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      if(type == 'delete') {
        this.deleteConfirm(order_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEquipDirectories()
      }).catch(()=>{
      });
    },
    exportEquipDirectories(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportWorkOrderInfoExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>